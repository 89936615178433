<template>
  <LanguageSwitcher
    v-if="isSurveyMultilingual"
    :user-survey-slug="userSurveySlug"
    :languages="languages"
    :first-page-is-orgstructure="firstPageIsOrgstructure"
  />
</template>

<script>
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { UserSurveyStatus } from '@/utils/constants';
import { mapMutations } from 'vuex';
import { toggleLoading, updateProgressBar, setCompanyId } from '@/plugins/vuex/mutationTypes';
import LanguageSwitcher from '@/views/LanguageSwitcher.vue';

export default {

  name: 'UserSurveyTechPage',

  components: { LanguageSwitcher },

  inject: ['Names'],

  data() {
    return {
      languages: [],
      UserSurveyStatus,
      firstPageIsOrgstructure: false,
    };
  },

  computed: {
    userSurveySlug() {
      return this.$route.params.slug;
    },
    isSurveyMultilingual() {
      return this.languages.length > 1;
    },
  },

  async created() {
    this[toggleLoading](true);
    try {
      const welcomeResponse = await apiService.surveySessionWelcomeGet(this.userSurveySlug);
      const {
        surveyName,
        status,
        orgstructurePageOrder,
        orgstructureRequired,
        companyId,
      } = welcomeResponse.data;
      this[updateProgressBar]({ surveyName });
      this[setCompanyId](companyId);
      if (status === this.UserSurveyStatus.DONE) {
        this.$router.push({ name: this.Names.R_USER_SURVEY_END });
        return;
      }
      this.firstPageIsOrgstructure = orgstructureRequired && (orgstructurePageOrder === 0);
      await this.getSurveyAvailableLanguages();

      if (!this.isSurveyMultilingual) {
        this.$router.push({ name: this.Names.R_USER_SURVEY_PAGE });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      this[toggleLoading](false);
    }
  },

  methods: {
    ...mapMutations([toggleLoading, updateProgressBar, setCompanyId]),
    async getSurveyAvailableLanguages() {
      this[toggleLoading](true);
      try {
        const { data } = await apiService.surveyLanguagesGet(this.userSurveySlug);
        this.languages = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
  },
};
</script>
